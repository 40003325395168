<template>
  <div>
    <organization-user-form
      :entity="entity"
      :submit="addEntity"
    />
  </div>
</template>

<script>
import UserEntity from '@/common/compositions/UserEntity/userEntityAPI'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import OrganizationUserForm from '@/common/components/UserEntities/OrganizationUserForm.vue'

export default {
  name: 'IndividualUserSignUp',
  components: { OrganizationUserForm },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { entity, storeRequest } = UserEntity()
    return { entity, storeRequest, successfulOperationAlert }
  },
  methods: {
    addEntity() {
      return this.storeRequest().then(() => {
        this.successfulOperationAlert('Entity is registered successfully')
        this.$router.push({
          name: 'login',
        })
      })
    },
  },
}
</script>

<style>

</style>

IndividualUserForm
