import { computed, ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleFormData from '../common/handleFormData'

const { formData, setFormData } = handleFormData()

export default function UserEntity() {
  const entity = ref({
    media: [],
    location: {},
  })

  const entityFormatted = computed(() => ({
    ...entity.value,
    ...entity.value.location,
  }))

  const storeRequest = () => {
    setFormData(entityFormatted.value)

    return axiosInstances.entities.post('/internalops/organization/portal/suggest', formData.value).then(res => res)
  }

  const storeReclaimRequest = () => {
    setFormData(entityFormatted.value)

    return axiosInstances.entities.post('/internalops/organization/resource/reclaim', formData.value).then(res => res)
  }

  return {
    entity, storeRequest, storeReclaimRequest,
  }
}
